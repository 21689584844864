

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); /* Filas con tamaño mínimo y máximo */
  gap: 5px 0; /* Eliminado el espacio vertical entre las celdas */
  grid-auto-flow: row;
  grid-template-areas:
    "Video1 Video2 Video3"
    "Video4 Video5 Video6"
    "Video7 Video8 Video9";
}
#Video1 { grid-area: Video1; }
#Video2 { grid-area: Video2; }
#Video3 { grid-area: Video3; }
#Video4 { grid-area: Video4; }
#Video5 { grid-area: Video5; }
#Video6 { grid-area: Video6; }
#Video7 { grid-area: Video7; }
#Video8 { grid-area: Video8; }
#Video9 { grid-area: Video9; }

html, body, .container {
  height: 100%;
  margin: 0;
  background-color: #7410f2;
}

/* For presentation only, no need to copy the code below */

.play_video {
  border: 0px solid #7410f2; /* Aplica bordes rojos a todos los elementos con la clase play_video */
  position: relative;
  width: 99%;
  height: 99%;
  overflow: hidden; /* Oculta cualquier contenido que exceda el tamaño de .play_video */
  background-color: black;

}

.video_js .plyr {
  height: inherit!important;
  width: inherit!important;
  min-width: 10px!important;
}
.plyr {
  height: inherit!important;
  width: inherit!important;
}


/*footer botones*/
.wrap {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  max-width: 100%; /* Establecer un ancho máximo para evitar que el contenedor se expanda demasiado */
}

.custom-btn {
  width: 60%;
  color: #fff;
  border-radius: 5px;
  padding: 5px 5px;
  font-family: 'Josefin Sans', serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(255, 255, 255, 0.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  background-color: #c644fc;
  background-image: linear-gradient(315deg, #c644fc 0%, #7410f2 74%);
  line-height: 10px;
  margin: 5px 0;
  border: none;
  min-height: 1px;
}
/* Reducción del tamaño de fuente cuando el contenedor se reduce */
@media screen and (max-width: 500px) {
  .custom-btn {
    width: 95%;
    font-size: 10px; /* Tamaño de fuente reducido */
    padding: 5px; /* Relleno reducido */
  }
}


.custom-btn i {
  font-size: 1.3em;
  vertical-align: middle;
  margin-right: 5px;
}
.custom-btn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.custom-btn span a{
  text-decoration: none;
  color: #ffffff;
}
.custom-btn span:hover a{
  color: #7410f2;
}
.custom-btn:before,
.custom-btn:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #c644fc;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.custom-btn:before{
  height: 0%;
  width: 2px;
}
.custom-btn:after {
  width: 0%;
  height: 2px;
}
.custom-btn:hover:before {
  height: 100%;
}
.custom-btn:hover:after {
  width: 100%;
}
.custom-btn:hover{
  background: transparent;
}
.custom-btn span:hover{
  color: #c644fc;
}
.custom-btn span:before,
.custom-btn span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #c644fc;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.custom-btn span:before {
  width: 2px;
  height: 0%;
}
.custom-btn span:after {
  height: 2px;
  width: 0%;
}
.custom-btn span:hover:before {
  height: 100%;
}
.custom-btn span:hover:after {
  width: 100%;
}


/* Estilos para ocultar elementos h1,h2 */
.hidden {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* canal 12*/
#Video2 iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}